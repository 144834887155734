
import React, { useState, useEffect } from 'react'
import { Tag,Row, Col, Typography, Card, Statistic, Input, Button, Table, Collapse, DatePicker  } from 'antd'
import {UserOutlined,UploadOutlined, DownloadOutlined, ClockCircleFilled, ArrowLeftOutlined } from '@ant-design/icons'
import logo from '../assets/images/logozivo.png'
import wallpaper  from '../assets/images/walldga.png'
import { Link } from "react-router-dom"
import minLogo  from '../assets/images/min_logo.png'

import caudal_img  from '../assets/images/caudal.png'
import nivel_img  from '../assets/images/nivel.png'
import acumulado_img  from '../assets/images/acumulado.png'
import pozo1  from '../assets/images/pozo1.png'
import { Area, Line } from '@ant-design/plots'
import g1 from '../assets/images/g1.png'


import kpi1_1 from '../assets/images/kpi1/1.png'
import kpi1_2 from '../assets/images/kpi1/2.png'
import kpi1_3 from '../assets/images/kpi1/3.png'
import kpi1_4 from '../assets/images/kpi1/4.png'
import kpi1_5 from '../assets/images/kpi1/5.png'
import kpi1_6 from '../assets/images/kpi1/6.png'

  
  
const { Title:TitleTy, Paragraph } =Typography
const { Countdown } = Statistic;

const Dga = () => {

    const deadline = Date.now() + 10 * 1 * 60 * 24 * 1 + 1000 * 3585; // Moment is also OK
    const [option, setOption] = useState(0)

    const [i1, setI1] = useState(0)
    const [i2, setI2] = useState(0)
    const [i3, setI3] = useState(0)
    const [i4, setI4] = useState(0)

    const Well = () => {
        return(
            <Row>
                 <Col span={5}>
                    <TitleTy level={2}>Mi Pozo</TitleTy>
                </Col>
                <Col span={13} offset={17} style={{marginTop:'-50px'}}>                                    
                                    <Countdown format="HH:mm:ss:SSS" title="Tiempo para nueva medición" value={deadline} prefix={<ClockCircleFilled />} />                                
                                </Col>
                                <Col span={12} style={{marginLeft:'-40px'}}>                                    
                                    <Card hoverable style={{marginBottom:'10px', marginTop:'20px', border:'solid 1px grey', borderRadius:'15px'}}>
                                        <Row align='middle'>
                                            <Col span={7}><img src={caudal_img} width='60px'  /></Col>
                                            <Col span={12}><TitleTy level={5} style={{color:'#222221'}}>Caudal</TitleTy></Col>
                                            <Col span={12} offset={7} style={{marginTop:'-15px'}}><Typography.Paragraph level={5}><b>51.5 (L/s)</b></Typography.Paragraph></Col>
                                        </Row>                                    
                                    </Card>
                                    <Card hoverable style={{marginBottom:'10px', marginTop:'20px', border:'solid 1px grey', borderRadius:'15px'}}>
                                        <Row align='middle'>
                                            <Col span={7}><img src={nivel_img} width='60px' /></Col>
                                            <Col span={12}><TitleTy level={5} style={{color:'#222221'}}>Nivel Freático</TitleTy></Col>                                            
                                            <Col span={12} offset={7} style={{marginTop:'-25px'}}><Typography.Paragraph level={5}><b>33.5 (m)</b></Typography.Paragraph></Col>
                                        </Row>                                    
                                    </Card>
                                    <Card hoverable style={{marginBottom:'50px', marginTop:'20px', border:'solid 1px grey', borderRadius:'15px'}}>
                                        <Row align='middle'>
                                            <Col span={7}><img src={acumulado_img} width='60px'  /></Col>
                                            <Col span={17}><TitleTy level={5} style={{color:'#222221'}}>Acumulado</TitleTy></Col>                                            
                                            <Col span={12} offset={7} style={{marginTop:'-25px'}}><Typography.Paragraph level={5}><b>35.179 (m³)</b></Typography.Paragraph></Col>
                                        </Row>                                    
                                    </Card>
                                </Col>
                                <Col span={12} style={{paddingLeft:'5px', paddingTop:'70px'}}>
                                    <center>
                                        <img src={pozo1} width={'330px'} style={{position:'absolute', marginLeft:'-140px'}} />                                        
                                    </center>
                                        <Input style={{width:'85px', marginTop:'80px', marginLeft:'50px', position:'absolute', borderRadius:'10px'}} value={'51.5 L/s'} />

                                        <Input style={{width:'90px', marginTop:'240px', marginLeft:'200px', position:'absolute', borderRadius:'10px'}} value={'33.5 m'}  />
                                        <Input style={{width:'95px', marginTop:'80px', marginLeft:'240px', position:'absolute', borderRadius:'10px'}} value={'35.179 m³'} />
                                </Col>
                                <Col>
                                </Col>
                            </Row>
        )
    }

    const Graphics = () => {
        
          
        const [option, setOption] = useState(0)
        
        const config = {
            data: [
                {timePeriod: '00:00', m3:0},
                {timePeriod: '01:00', m3:33},
                {timePeriod: '02:00', m3:40},
                {timePeriod: '03:00', m3:'30'},
                {timePeriod: '04:00', m3:'26'},
                {timePeriod: '05:00', m3:'46'},
                {timePeriod: '06:00', m3:'58'},
                {timePeriod: '07:00', m3:'66'},
                {timePeriod: '08:00', m3:'92'},
                {timePeriod: '09:00', m3:'42'},
                {timePeriod: '10:00', m3:'64'},
                {timePeriod: '11:00', m3:'16'},
                {timePeriod: '12:00', m3:'73'},
                {timePeriod: '13:00', m3:'55'},
                {timePeriod: '14:00', m3:'28'},
                {timePeriod: '15:00', m3:'12'},
                {timePeriod: '16:00', m3:'63'},
                {timePeriod: '17:00', m3:'16'},
                {timePeriod: '18:00', m3:'35'},
                {timePeriod: '19:00', m3:'79'},
                {timePeriod: '20:00', m3:'16'},
                {timePeriod: '21:00', m3:'17'},
                {timePeriod: '22:00', m3:'57'},
                {timePeriod: '23:00', m3:'42'},                
            ],
            xField: 'timePeriod',
            yField: 'm3',            
            
          }

          const config2 = {
            data: [
                {timePeriod: '00:00', m:12},
                {timePeriod: '01:00', m:17},
                {timePeriod: '02:00', m:15},
                {timePeriod: '03:00', m:'17'},
                {timePeriod: '04:00', m:'14'},
                {timePeriod: '05:00', m:'12'},
                {timePeriod: '06:00', m:'12'},
                {timePeriod: '07:00', m:'12'},
                {timePeriod: '08:00', m:'12'},
                {timePeriod: '09:00', m:'12'},
                {timePeriod: '10:00', m:'12'},
                {timePeriod: '11:00', m:'12'},
                {timePeriod: '12:00', m:'12'},
                {timePeriod: '13:00', m:'10'},
                {timePeriod: '14:00', m:'12'},
                {timePeriod: '15:00', m:'12'},
                {timePeriod: '16:00', m:'7'},
                {timePeriod: '17:00', m:'10'},
                {timePeriod: '18:00', m:'10'},
                {timePeriod: '19:00', m:'12'},
                {timePeriod: '20:00', m:'12'},
                {timePeriod: '21:00', m:'10'},
                {timePeriod: '22:00', m:'9'},
                {timePeriod: '23:00', m:'12'},      
            ],
            xField: 'timePeriod',
            yField: 'm',            
          }

          const config3 = {
            data: [
                {timePeriod: '00:00', ['L/s']:8},
                {timePeriod: '01:00', ['L/s']:12},
                {timePeriod: '02:00', ['L/s']:18},
                {timePeriod: '03:00', ['L/s']:'14'},
                {timePeriod: '04:00', ['L/s']:'20'},
                {timePeriod: '05:00', ['L/s']:'17'},
                {timePeriod: '06:00', ['L/s']:'0'},
                {timePeriod: '07:00', ['L/s']:'16'},
                {timePeriod: '08:00', ['L/s']:'21'},
                {timePeriod: '09:00', ['L/s']:'12'},
                {timePeriod: '10:00', ['L/s']:'12'},
                {timePeriod: '11:00', ['L/s']:'0'},
                {timePeriod: '12:00', ['L/s']:'12'},
                {timePeriod: '13:00', ['L/s']:'11'},
                {timePeriod: '14:00', ['L/s']:'0'},
                {timePeriod: '15:00', ['L/s']:'12'},
                {timePeriod: '16:00', ['L/s']:'7'},
                {timePeriod: '17:00', ['L/s']:'16'},
                {timePeriod: '18:00', ['L/s']:'12'},
                {timePeriod: '19:00', ['L/s']:'17'},
                {timePeriod: '20:00', ['L/s']:'12'},
                {timePeriod: '21:00', ['L/s']:'17'},
                {timePeriod: '22:00', ['L/s']:'8'},
                {timePeriod: '23:00', ['L/s']:'12'},      
            ],
            xField: 'timePeriod',
            yField: ['L/s'],            
          }
        
        return(
            <Row>
                 <Col span={24}>
                    <TitleTy level={2}>Gráficos</TitleTy>
                </Col>
                    <Col span={24} >
                       <center>
                        <DatePicker style={{marginRight:'10px', width:'230px', borderRadius:'10px'}} placeholder='Selecciona una fecha inicial' />
                        <DatePicker style={{marginRight:'20px',width:'230px', borderRadius:'10px'}} placeholder='Selecciona una fecha final' />
                        <Button onClick={()=>setOption(0)} type='primary' style={{marginRight:'4px', borderRadius:'10px'}} size='small'>Acumulado(m³)</Button>
                        <Button onClick={()=>setOption(1)} type='primary' style={{marginRight:'4px',  borderRadius:'10px'}} size='small' >Caudal(L/s)</Button>
                        <Button onClick={()=>setOption(2)} type='primary' style={{marginRight:'4px',  borderRadius:'10px'}} size='small' >Nivel(m)</Button></center>                         
                    </Col>
                    <Col span={24} style={{padding:'20px'}}>
                        {option==0 && <Line {...config} />}
                        {option==1 && <Area {...config3} />}
                        {option==2 && <Line {...config2} />}
                    </Col>
                </Row>
        )
    }

    const Analituc = () => {
        return(<Row>
            <Col span={24}>
                    <TitleTy style={{marginBottom:'40px'}} level={2}>Indicadores</TitleTy>
                </Col>

            <Col span={12} style={{marginBottom:'10px'}}>                
                <Card hoverable style={{borderRadius:'10px', width:'350px'}} bordered>
                    <TitleTy level={5}>Peak de Consumo (m³)</TitleTy>
                    <DatePicker placeholder='Fecha inicial' style={{marginRight:'5px'}}/>
                    <DatePicker placeholder='Fecha final' onChange={()=>{setI1([1000,100])}} /><br/><br/>
                    <Tag style={{fontSize:'15px', marginBottom:'5px', marginRight:'10px'}} color='blue'>Max: {i1[0]} m³</Tag>
                    <Tag style={{fontSize:'15px', marginBottom:'5px'}} color='volcano'>Min: {i1[1]} m³</Tag><br/>
                </Card>
            </Col>
            <Col span={12} style={{marginBottom:'10px'}}>
                <Card hoverable style={{borderRadius:'10px', width:'350px'}} bordered>
                        <TitleTy level={5}>Peak Nivel Freático (m)</TitleTy>
                        <DatePicker placeholder='Fecha inicial' style={{marginRight:'5px'}} />
                        <DatePicker placeholder='Fecha final' onChange={()=>setI2([20,5])} /><br/><br/>
                        <Tag style={{fontSize:'15px', marginBottom:'5px', marginRight:'10px'}} color='blue'>Max: {i2[0]} m</Tag>
                        <Tag style={{fontSize:'15px', marginBottom:'5px'}} color='volcano'>Min: {i2[1]} m</Tag><br/>
                </Card>                        
            </Col>
            <Col span={12} style={{marginBottom:'10px'}}>
            <Card hoverable style={{borderRadius:'10px', width:'350px'}} bordered>
                        <TitleTy level={5}>Peak Caudal Histórico (L/s)</TitleTy>
                        <DatePicker placeholder='Fecha inicial' style={{marginRight:'5px'}} />
                        <DatePicker placeholder='Fecha final' onChange={()=>setI3([45,14])} /><br/><br/>
                        <Tag style={{fontSize:'15px', marginBottom:'5px', marginRight:'10px'}} color='blue'>Max: {i3[0]} L/s</Tag>
                        <Tag style={{fontSize:'15px', marginBottom:'5px'}} color='volcano'>Min: {i3[1]} L/s</Tag><br/>
                </Card> 
            </Col>
            <Col span={12} style={{marginBottom:'10px'}}>
            <Card hoverable style={{borderRadius:'10px', width:'350px'}} bordered>
                        <TitleTy level={5}>Consumo promedio (m³)</TitleTy>
                        <DatePicker placeholder='Fecha inicial' style={{marginRight:'5px'}} />
                        <DatePicker placeholder='Fecha final' onChange={()=>setI4([30])} /><br/><br/>
                        <Tag style={{fontSize:'15px', marginBottom:'5px', marginRight:'10px'}} color='blue'>Consumo: {i4[0]} m³ </Tag>                        
                </Card> 
            </Col>
            
            
        </Row>)
    }    
    const ListPersons = () => {
        return(<Row>
            <Col span={24}>
                    <TitleTy level={2}>Documentación y Respaldo</TitleTy>
                </Col>
                <Col span={12} style={{marginTop:'10px', marginBottom:'10px', paddingRight:'20px'}}>
                    <TitleTy level={4}>Documentación Cliente</TitleTy>
                    <Table bordered columns={[
                        {title:'#', dataIndex:'number'},                        
                        {title:'Nombre', dataIndex:'name'},
                        {title:'', dataIndex: 'phone'},
                    ]} dataSource = {[
                        {
                            key: '1',
                            number: '1',
                            name: 'DA DGA',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<UploadOutlined />}>Cargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '2',
                            name: 'Inscripción DA en CBR',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<UploadOutlined />}>Cargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '3',
                            name: 'Prueba de bombeo',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<UploadOutlined />}>Cargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '4',
                            name: 'Informe mantención pozo',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<UploadOutlined />}>Cargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '4',
                            name: <Input placeholder='Cargar Nuevo Archivo' />,                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<UploadOutlined />}>Cargar archivo</Button>,
                        }
                      ]} />
                </Col>
                <Col span={12} style={{marginTop:'10px', marginBottom:'10px'}}>
                <TitleTy level={4}>Documentación Smart Hydro</TitleTy>
                
                <Table bordered columns={[
                        {title:'#', dataIndex:'number'},                        
                        {title:'Nombre', dataIndex:'name'},
                        {title:'', dataIndex: 'phone'},
                    ]} dataSource = {[
                        {
                            key: '1',
                            number: '1',
                            name: 'Ficha levantamiento',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<DownloadOutlined />}>Descargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '2',
                            name: 'Cotización',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<DownloadOutlined />}>Descargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '3',
                            name: 'OC',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<DownloadOutlined />}>Descargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '4',
                            name: 'Comprobantes de pago',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<DownloadOutlined />}>Descargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '5',
                            name: 'Contrato',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<DownloadOutlined />}>Descargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '6',
                            name: 'Ficha técnica equipos MEE',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<DownloadOutlined />}>Descargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '7',
                            name: 'Informe técnico',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<DownloadOutlined />}>Descargar archivo</Button>,
                        },
                        {
                            key: '1',
                            number: '8',
                            name: 'Garantías',                            
                            phone: <Button size='small' style={{borderRadius:'10px'}} type='primary' icon={<DownloadOutlined />}>Descargar archivo</Button>,
                        },
                      ]} />
                </Col>
        </Row>)
    }   

    const MeeDGA = () => {

        return(<Row>
            <Col span={24}>
                <TitleTy level={3}>MEE DGA</TitleTy>
            </Col>
            <Col span={12}>
                <TitleTy level={5}>Últimos datos enviados a la DGA en las últimas 24 hrs.</TitleTy>
                <Table size='small' style={{borderRadius:'10px'}} columns={[
                    { title:'Fecha' , dataIndex:'fecha'},
                    { title:'Nivel(mt)', dataIndex:'nivel' },
                    { title:'Caudal(lt)', dataIndex:'caudal' },
                    { title:'Acumulado(m³)', dataIndex:'acumulado' }, 
                ]} dataSource={[
                    { fecha:'2023-01-01 23:00', nivel:'2.0', caudal: '1.0', acumulado:'1000' },
                    { fecha:'2023-01-01 22:00', nivel:'12.0', caudal: '2.0', acumulado:'1100' },
                    { fecha:'2023-01-01 21:00', nivel:'2.0', caudal: '3.0', acumulado:'1200' },
                    { fecha:'2023-01-01 20:00', nivel:'12.0', caudal: '4.0', acumulado:'1300' },
                    { fecha:'2023-01-01 19:00', nivel:'21.0', caudal: '5.0', acumulado:'1400' },
                    { fecha:'2023-01-01 18:00', nivel:'3.0', caudal: '1.0', acumulado:'1500' },
                    { fecha:'2023-01-01 17:00', nivel:'6.0', caudal: '2.0', acumulado:'1600' },
                    { fecha:'2023-01-01 16:00', nivel:'9.0', caudal: '3.0', acumulado:'1700' },
                    { fecha:'2023-01-01 15:00', nivel:'12.0', caudal: '4.0', acumulado:'1800' },
                    { fecha:'2023-01-01 14:00', nivel:'2.0', caudal: '5.0', acumulado:'1900' },
                    { fecha:'2023-01-01 13:00', nivel:'3.0', caudal: '1.0', acumulado:'2000' },
                    { fecha:'2023-01-01 12:00', nivel:'9.0', caudal: '2.0', acumulado:'2100' },
                    { fecha:'2023-01-01 11:00', nivel:'12.0', caudal: '3.0', acumulado:'2200' },
                    { fecha:'2023-01-01 10:00', nivel:'9.0', caudal: '4.0', acumulado:'2300' },
                    { fecha:'2023-01-01 09:00', nivel:'8.0', caudal: '5.0', acumulado:'2400' },
                    { fecha:'2023-01-01 08:00', nivel:'7.0', caudal: '1.0', acumulado:'2500' },
                    { fecha:'2023-01-01 07:00', nivel:'4.0', caudal: '2.0', acumulado:'2600' },
                    { fecha:'2023-01-01 06:00', nivel:'1.0', caudal: '3.0', acumulado:'2700' },
                    { fecha:'2023-01-01 05:00', nivel:'18.0', caudal: '5.0', acumulado:'2800' },
                    { fecha:'2023-01-01 04:00', nivel:'2.0', caudal: '1.0', acumulado:'2900' },
                    { fecha:'2023-01-01 03:00', nivel:'7.0', caudal: '2.0', acumulado:'3000' },
                    { fecha:'2023-01-01 02:00', nivel:'1.0', caudal: '3.0', acumulado:'3100' },
                    { fecha:'2023-01-01 01:00', nivel:'7.0', caudal: '4.0', acumulado:'3200' },
                    { fecha:'2023-01-01 00:00', nivel:'9.0', caudal: '5.0', acumulado:'3300' },
                ]}></Table>
            </Col>
            <Col span={12} style={{marginTop:'-57px'}}><center>
                <TitleTy level={4}>Consumo máximo anual:<br/> 100.000 m³ / 50.000 m³</TitleTy></center>
            <Table pagination={false} size='small' style={{paddingLeft:'10px', marginBottom:'5px', marginTop:'32px'}} columns={[
                    { title:'Nombre alarma' , dataIndex:'fecha'},
                    { title:'Correo Responsable', dataIndex:'nivel' },                    
                    { title:'Acumulado(m³)', dataIndex:'acumulado' }, 
                ]} dataSource={[
                    { fecha:'Alerta 1', nivel:'cliente@dominio.cl' , acumulado:'80.000' },
                    { fecha:'Alerta 2', nivel:'cliente@dominio.cl', acumulado:'90.000' },                    
                ]}></Table>
                <center>                    
                    <img src='https://api.smarthydro.cl//media/Screenshot_2023-08-09_at_11.04.07.png' width={'60%'} />
                    <TitleTy level={5} style={{marginTop:'10px'}}>OB-1234-21</TitleTy>
                    <Button type='primary' style={{borderRadius:'10px', marginRight:'10px'}}>Ir a mi DGA</Button>
                    <Button type='primary' style={{borderRadius:'10px'}}>Descargar QR</Button><br/>
                </center>
            </Col>
        </Row>)
    }

    const DataReport = () => {

        return(<Row>
            <Col span={24}>
                <TitleTy level={3}>Datos y Reportes</TitleTy>
                <TitleTy level={5}>Histórico de datos enviados a la DGA(Fecha - L/s - m³ - m).</TitleTy>
            </Col>
            <Col span={12}>
                
                <Table size='small' style={{borderRadius:'10px'}} columns={[
                    { title:'Fecha' , dataIndex:'fecha'},
                    { title:'Nivel(m)', dataIndex:'nivel' },
                    { title:'Caudal(l)', dataIndex:'caudal' },
                    { title:'Acumulado(m³)', dataIndex:'acumulado' }, 
                ]} dataSource={[
                    { fecha:'2023-01-01 23:00', nivel:'2.0', caudal: '1.0', acumulado:'10.000.000' },
                    { fecha:'2023-01-01 22:00', nivel:'12.0', caudal: '2.0', acumulado:'9.900.000' },
                    { fecha:'2023-01-01 21:00', nivel:'2.0', caudal: '3.0', acumulado:'9.800.000' },
                    { fecha:'2023-01-01 20:00', nivel:'12.0', caudal: '4.0', acumulado:'9.700.000' },
                    { fecha:'2023-01-01 19:00', nivel:'21.0', caudal: '5.0', acumulado:'9.600.000' },
                    { fecha:'2023-01-01 18:00', nivel:'3.0', caudal: '1.0', acumulado:'9.500.000' },
                    { fecha:'2023-01-01 17:00', nivel:'6.0', caudal: '2.0', acumulado:'9.400.000' },
                    { fecha:'2023-01-01 16:00', nivel:'9.0', caudal: '3.0', acumulado:'9.300.000' },
                    { fecha:'2023-01-01 15:00', nivel:'12.0', caudal: '4.0', acumulado:'9.200.000' },
                    { fecha:'2023-01-01 14:00', nivel:'2.0', caudal: '5.0', acumulado:'9.100.000' },
                    { fecha:'2023-01-01 13:00', nivel:'3.0', caudal: '1.0', acumulado:'8.000.000' },
                    { fecha:'2023-01-01 12:00', nivel:'9.0', caudal: '2.0', acumulado:'8.900.000' },
                    { fecha:'2023-01-01 11:00', nivel:'12.0', caudal: '3.0', acumulado:'8.800.000' },
                    { fecha:'2023-01-01 10:00', nivel:'9.0', caudal: '4.0', acumulado:'8.700.000' },
                    { fecha:'2023-01-01 09:00', nivel:'8.0', caudal: '5.0', acumulado:'8.600.000' },
                    { fecha:'2023-01-01 08:00', nivel:'7.0', caudal: '1.0', acumulado:'8.500.000' },
                    { fecha:'2023-01-01 07:00', nivel:'4.0', caudal: '2.0', acumulado:'8.400.000' },
                    { fecha:'2023-01-01 06:00', nivel:'1.0', caudal: '3.0', acumulado:'8.300.000' },
                    { fecha:'2023-01-01 05:00', nivel:'18.0', caudal: '5.0', acumulado:'8.200.000' },
                    { fecha:'2023-01-01 04:00', nivel:'2.0', caudal: '1.0', acumulado:'8.100.000' },
                    { fecha:'2023-01-01 03:00', nivel:'7.0', caudal: '2.0', acumulado:'8.000.000' },
                    { fecha:'2023-01-01 02:00', nivel:'1.0', caudal: '3.0', acumulado:'7.900.000' },
                    { fecha:'2023-01-01 01:00', nivel:'7.0', caudal: '4.0', acumulado:'7.800.000' },
                    { fecha:'2023-01-01 00:00', nivel:'9.0', caudal: '5.0', acumulado:'7.700.000' },
                ]}></Table>
            </Col>
            <Col span={12} style={{marginTop:'-57px'}}>
                            
                <center>                    
                    <TitleTy style={{marginTop:'100px'}} level={4} >Descargar Reporte</TitleTy>
                    <DatePicker style={{width:'300px', marginBottom:'10px'}} placeholder='Selecciona la fecha inicial' />
                    <DatePicker style={{width:'300px', marginBottom:'20px' }} placeholder='Selecciona la fecha final' /> 
                    <Button type='primary' style={{borderRadius:'10px'}}>Descargar datos en .xlsx</Button><br/>
                </center>
            </Col>
        </Row>)
    }

    const Supp = () => {
        return(<Row>
            <Col span={24}>
            <TitleTy level={3}>Soporte Técnico</TitleTy> 
            </Col>
            <Col span={12} style={{marginTop:'30px', paddingRight:'10px'}}>
                <TitleTy level={4}>Abrir Ticket #12345</TitleTy>
                <Paragraph>Este mensaje será recepcionado por el area de soporte técnico, para mayor información contactarse a <a>soporte@smarthydro.cl</a></Paragraph>                
                <Input.TextArea rows={7} style={{borderRadius:'10px'}} placeholder='Describe tu problema...'></Input.TextArea>
                <Paragraph style={{marginTop:'10px', color:'gray', fontSize:'13px'}}>Su Ticket será contestado en las próximas 48 horas(días habiles).</Paragraph>                
                <Button style={{borderRadius:'10px', marginTop:'20px'}} type='primary'>Envíar Ticket</Button>
            </Col>
            <Col span={12} style={{paddingLeft:'10px'}}>
                <TitleTy level={4}> Preguntas Frecuentes </TitleTy>
                <Collapse style={{borderRadius:'10px'}}>
                    <Collapse.Panel style={{borderRadius:'10px'}} header=' ¿Qué es el Software D.G.A. de Monitoreo de Extracciones Efectivas (MEE)?'>
                        <Paragraph alig='justify'>Corresponde a una plataforma informática a la que los titulares de derechos de
aprovechamiento de aguas y/o las personas informantes que ellos designen, acceden
mediante Clave Única del Registro Civil
                        </Paragraph>
                    </Collapse.Panel>
                    <Collapse.Panel style={{borderRadius:'10px'}} header='¿Qué es el Sistema de Monitoreo de Extracciones Efectivas de Aguas (MEE)?'>
                        <Paragraph>
                        Corresponde a un conjunto de normas y procedimientos que la D.G.A., por resolución
                        fundada, establece en el marco de la implementación de la Ley N° 21.064 de 27 de enero
                        de 2018 que modificó el Código de Aguas, para que los titulares de derechos de
                        aprovechamiento midan las extracciones efectivas de aguas que realizan desde los puntos
                        de captación así como sus niveles freáticos y transmitan la información generada a la D.G.A.
                        Este Sistema cuenta con una herramienta informática denominada Software D.G.A. de
                        Monitoreo de Extracciones Efectivas.
                        </Paragraph>
                    </Collapse.Panel>
                    <Collapse.Panel style={{borderRadius:'10px'}} header='¿Quiénes se deben registrar e ingresar información técnica y legal a través del Software D.G.A. de Monitoreo de Extracciones Efectivas de Aguas?'>
                        <Paragraph>
                        Todos los titulares de derechos de aprovechamiento de aguas que por Resolución D.G.A. se
                        les haya ordenado el Monitoreo de Extracciones Efectivas y Control de Extracciones,
                        incluidos los derechos de aprovechamiento reconocidos por elsólo ministerio de la ley
                        </Paragraph>
                    </Collapse.Panel>
                    <Collapse.Panel style={{borderRadius:'10px'}} header='¿Cuáles son los formatos para transmitir los datos al Software D.G.A. de Monitoreo de Extracciones Efectivas?'>
                        <Paragraph>
                        El Software D.G.A. de Monitoreo de Extracciones Efectivas está construido para recibir los
datos de totalizador, caudal y nivel freático a través de archivo formato Excel y XML. Dichos
formatos se pueden bajar en la página web D.G.A.
(<a>https://dga.mop.gob.cl/controlExtracciones/Paginas/documentos.aspx#</a>), en la sección de
Monitoreo de Extracciones Efectivas.<br/><br/>
También existe la posibilidad de subir datos de totalizador, caudal y nivel freático por
Formulario (llenado a mano). El titular de derecho de aprovechamiento de aguas deberá
emplear el tipo de transmisión que le haya sido ordenado.
                        </Paragraph>
                    </Collapse.Panel>
                    <Collapse.Panel style={{borderRadius:'10px'}} header=' ¿Cómo puedo borrar mediciones ya registradas en el software?'>
                        <Paragraph align='justify'>
                        El procedimiento para solicitar el borrado de mediciones de una obra se encuentra disponible en el Manual de Usuario Monitoreo de Extracciones Efectivas, disponible en la página web de la Dirección General de Aguas.
                        </Paragraph>
                    </Collapse.Panel>
                </Collapse>
            </Col>
        </Row>)
    }

    return(<Row align={'top'} style={{
        backgroundImage:`url(${wallpaper})`,
        minHeight: '720px',    
        /* Create the parallax 
        scrolling effect */
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundColor:'rgb(255,255,255,0,0.7)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }}>
        <Col style={{paddingTop:'30px', paddingLeft:'40px'}} span={12}>
            <TitleTy level={3} style={{color:'white'}}>Monitoreo Extracciones Efectivas (DGA)</TitleTy>
        </Col>
        <Col style={{paddingTop:'30px'}} span={3} offset={8}>
            <UserOutlined style={{backgroundColor:'grey', color:'white', fontSize:'15px', borderRadius:'100%', padding:'10px', marginRight:'10px'}} />
            <Link to='/mide'><ArrowLeftOutlined style={{backgroundColor:'#3968A9', color:'white', fontSize:'15px', borderRadius:'100%', padding:'10px'}}/></Link>
            
        </Col>                
        <Col span={24} style={{marginTop:'10px'}}>
            <Card style={{borderRadius:'30px 30px 0px 0px'}}>
                <Row>
                    <Col span={3} style={{paddingRight:'10px'}}>
                        <Card hoverable={true} style={{backgroundColor:'#1F3461', borderRadius:'20px'}}>
                            <center><img src={logo} width='50px' style={{marginBottom:'40px', }} /></center>                            
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px',  marginBottom:'15px'}}>
                                <Button onClick={()=>setOption(0)} type='link' style={{color:'#1F3461'}}>Mi Pozo</Button>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px',  marginBottom:'15px'}}>
                                <Button onClick={()=>setOption(4)} type='link' style={{color:'#1F3461'}}>MEE DGA</Button>                                
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px',  marginBottom:'15px', }}>
                                <Button onClick={()=>setOption(5)} type='link' style={{color:'#1F3461'}}>Datos y Reportes</Button>                                
                            </div>                                                        
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px',  marginBottom:'15px'}}>
                                <Button onClick={()=>setOption(1)} type='link' style={{color:'#1F3461'}}>Gráficos</Button>                                
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px',  marginBottom:'15px', }}>
                                <Button onClick={()=>setOption(2)} type='link' style={{color:'#1F3461'}}>Indicadores</Button>                                
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px',  marginBottom:'15px', paddingBottom:'20px' }}>
                                <Button onClick={()=>setOption(3)} type='link' style={{color:'#1F3461'}}>Documentación y <br/> respaldo</Button>                                
                            </div>                                                        
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px',  marginBottom:'50px', paddingBottom:'0px' }}>
                                <Button onClick={()=>setOption(6)} type='link' style={{color:'#1F3461'}}>Soporte Técnico</Button>                                
                            </div>                                                        
                            <div>
                                <center><img src={minLogo} /></center>
                            </div>
                        </Card>
                    </Col>
                    <Col span={17} style={{paddingRight:'5px'}}>
                        <Card hoverable={true} style={{backgroundColor:'#fafafa', borderRadius:'20px', marginLeft:'10px'}}>
                            {option == 0 && <Well />}                            
                            {option == 1 && <Graphics />}                            
                            {option == 2 && <Analituc />}                            
                            {option == 3 && <ListPersons />}                            
                            {option == 4 && <MeeDGA />}      
                            {option == 5 && <DataReport />}      
                            {option == 6 && <Supp />}      
                        </Card>
                    </Col>
                    <Col span={4} >
                        <Card hoverable={true} style={{backgroundColor:'#1F3461', borderRadius:'20px'}}>
                            <TitleTy align='center' style={{color:'white', marginTop:'-20px'}} level={3}>NOMBRE</TitleTy>
                            <TitleTy align='center' style={{color:'white', marginTop:'-20px'}} level={5}>ESTÁNDAR</TitleTy>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                Caudal Otrogado por la DGA:<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>50 L/s</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                SHAC:<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>Nombre sector</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                Fecha inicio monitoreo(Ikolu):<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>2023-01-01</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                Fecha inicio MEE DGA:<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>2023-02-01</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                Fecha firma acta de entrega:<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>2023-01-01</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                Profundida del pozo:<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>170 mtrs</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                Posicionamiento de bomba:<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>71 mtrs</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                Posicionamiento Sensor de nivel:<br/>
                                <b><Typography.Paragraph style={{fontSize:'14x'}}>76 mtrs</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-3px'}}>
                                Diámetro ducto de salida bomba<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>8 pulg</Typography.Paragraph></b>
                            </div>
                            <div style={{textAlign:'center', backgroundColor:'white', marginLeft:'-24px', marginRight:'-24px', marginBottom:'-5px'}}>
                                Diámetro flujometro<br/>
                                <b><Typography.Paragraph style={{fontSize:'14px'}}>8 pulg</Typography.Paragraph></b>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>)
}


export default Dga
